angular.module('samcart.factories').factory('Checkout', ['$http', 'Order', '$window', 'StripeElements', 'EasyPayDirect', function($http, Order, $window, StripeElements, EasyPayDirect) {
    var Checkout = {

        cart_token : "",
        listeners: [],
        view : {},
        queue: [],

        init : function() {
            this.state = "checkout";
        },

        getContext: function(data, callback) {
            $http.post('/api/v2/checkout/context', data).success(function(response) {
                callback(response);
            });
        },

        captureProspect: function(prospect, callback) {
            $http.post('/api/prospect/capture', prospect).success(function(data) {
                callback(data);
            });
        },

        nextState : function(data) {
            if (data.url_redirect !== null && data.url_redirect !== 'null' && data.url_redirect !== '') {
                this.handleRedirect(data.url_redirect);
            } else {
                this.trigger("show_funnel_item", data.template);
            }
        },

        submit : function(orderData, checkoutContext, stripeElement, braintreeHostedFieldsInstance, callback) {
            this.funnelStart();
            var _this = this;
            this.tokenizeCard(orderData, checkoutContext, stripeElement, braintreeHostedFieldsInstance, function (orderData, checkoutContext, tokenized) {
                // if we fail to get the token for any processor except Spreedly exit out and invoke the callback
                if (!tokenized && !$window.samcart.spreedly) {
                    callback();
                } else {
                    _this.captureOrder(orderData, checkoutContext).then(function() {
                        callback();
                    }).catch(function() {
                        callback();
                    });
                }
            });
        },

        submitSamPay : function(orderData, checkoutContext, elements, callback) {
            this.funnelStart();
            var _this = this;
            this.tokenizePaymentMethodWithStripe(orderData, checkoutContext, elements, function (orderData, checkoutContext, tokenized) {
                // if we fail to get the token for any processor except Spreedly exit out and invoke the callback
                if (!tokenized && !$window.samcart.spreedly) {
                    callback();
                } else {
                    _this.captureOrder(orderData, checkoutContext).then(function() {
                        callback();
                    }).catch(function() {
                        callback();
                    });
                }
            });
        },

        captureOrder : function(orderData, checkoutContext) {
            var _this = this;

            return Order.capture(orderData, checkoutContext).then(function(response) {
                if (response.success) {
                    _this.nextState(response.data);
                    _this.processingComplete();
                } else {
                    // Stripe specific: Handle SCA case. Handling for other processors to be added later.
                    if ($window.samcart.stripe && (typeof response.data !== 'undefined') && response.data !== null && response.data.hasOwnProperty('client_secret')) {
                        // Use Stripe.js to handle required card action
                        if (response.data['client_secret'].startsWith('pi')) {
                            StripeElements.handleCardAction(
                                response.data['client_secret']
                            ).then(function(result) {
                                if (result.error) {
                                    _this.showErrorModal(result.error.message);
                                } else {
                                    // SCA card actions have taken place successfully
                                    // Resubmit with Stripe PaymentIntent
                                    orderData.sca_reference_id = result.paymentIntent.id;
                                    checkoutContext.payment.sca_reference_id = result.paymentIntent.id;
                                    _this.captureOrder(orderData, checkoutContext);
                                }
                            });
                        } else if (response.data['client_secret'].startsWith('seti')) {
                            StripeElements.handleNextAction({
                                clientSecret: response.data['client_secret']
                            }).then(function(result) {
                                if (result.error) {
                                    _this.showErrorModal(result.error.message);
                                } else {
                                    // SCA card actions have taken place successfully
                                    // Resubmit with Stripe PaymentIntent
                                    orderData.sca_reference_id = result.setupIntent.id;
                                    checkoutContext.payment.sca_reference_id = result.setupIntent.id;
                                    _this.captureOrder(orderData, checkoutContext);
                                }
                            });
                        }
                    } else {
                        orderData.sca_reference_id = null;
                        checkoutContext.payment.sca_reference_id = null;
                        if (response.data && response.data.error_message !== null)
                            _this.showErrorModal('<h2>' + response.data.error_message + '</h2>');
                        else
                            _this.showErrorModal(response.template);
                    }
                    _this.processingComplete();
                }
            }).catch(function (response) {
                _this.displayError(response.data);
            });
        },

        submitPayPal: function(orderData, checkoutContext, callback) {
            this.funnelStart();
            var _this = this;

            Order.capture(orderData, checkoutContext).then(function (response) {
                if (response.success) {
                    if (typeof response.data.cart_token != 'undefined' && response.data.cart_token != '') {
                        callback(response.data.cart_token);
                    } else {
                        _this.showErrorModal("There was an error processing with paypal. You have not been charged. Please try again.");
                        _this.processingComplete();
                    }
                } else {
                    _this.showErrorModal(response.template);
                    _this.processingComplete();
                }
            });
        },

        submitPayPalExpress: function(orderData, checkoutContext, callback) {
            this.funnelStart();
            var _this = this;

            Order.capture(orderData, checkoutContext).then(function (response) {
                if (response.success) {
                    if (typeof response.data.paypal_token != 'undefined' && response.data.paypal_token != '') {
                        callback(response.data);
                    } else {
                        _this.showErrorModal("There was an error processing with paypal. You have not been charged. Please try again.");
                        _this.processingComplete();
                    }
                } else {
                    _this.showErrorModal(response.template);
                    _this.processingComplete();
                }
            });
        },

        retry : function(order_data, stripeElement, braintreeHostedFieldsInstance) {
            this.funnelStart();
            var _this = this;

            this.tokenizeCard(order_data, { payment: {} }, stripeElement, braintreeHostedFieldsInstance, function(orderData, checkoutContext, tokenized) {
                _this.retryOrder(orderData)
            });
        },

        retryOrder : function(orderData) {
            var _this = this;

            Order.retry(orderData, function (response) {
                if (response.success) {
                    _this.nextState(response.data);
                    _this.processingComplete();
                } else {
                    // Stripe specific: Handle SCA case. Handling for other processors to be added later.
                    if ($window.samcart.stripe && (typeof response.data !== 'undefined') && response.data !== null && response.data.hasOwnProperty('client_secret')) {
                        // Use Stripe.js to handle required card action
                        StripeElements.handleCardAction(
                            response.data['client_secret']
                        ).then(function(result) {
                            if (result.error) {
                                _this.showErrorModal(result.error.message);
                            } else {
                                // SCA card actions have taken place successfully
                                // Resubmit with Stripe PaymentIntent
                                orderData.sca_reference_id = result.paymentIntent.id;
                                _this.retryOrder(orderData)
                            }
                        });
                    } else {
                        _this.showErrorModal(response.template);
                    }
                    _this.processingComplete();
                }
            });
        },

        acceptUpsell : function(upsell_token, cart_token, scaReferenceId, product_id, upsellCustomFields, isGift) {
            this.hideFunnelItem();
            var _this = this;
            Order.acceptUpsell(upsell_token, cart_token, scaReferenceId, product_id, upsellCustomFields, isGift, function(response) {
                if (response.success) {
                    _this.nextState(response.data);
                    _this.processingComplete();
                } else {
                    if (response.data.url_redirect !== null && response.data.url_redirect !== 'null' && response.data.url_redirect !== '') {
                        if (response.data.next_step_type == 'expired_cart') {
                            _this.processingComplete();
                            _this.showErrorModal("This session has expired please wait to be redirected or click <a href='"+response.data.url_redirect+"'>here.</a>", function(){
                                $window.location = response.data.url_redirect;
                            });
                            $window.setTimeout(function(){
                            }, 3000);
                        } else {
                            // Stripe specific: Handle SCA case. Handling for other processors to be added later.
                            if ($window.samcart.stripe && (typeof response.data !== 'undefined') && response.data !== null && response.data.hasOwnProperty('client_secret')) {
                                // Use Stripe.js to handle required card action
                                StripeElements.handleCardAction(
                                    response.data['client_secret']
                                ).then(function(result) {
                                    if (result.error) {
                                        _this.showErrorModal(result.error.message);
                                    } else {
                                        // SCA card actions have taken place successfully
                                        // Resubmit with Stripe PaymentIntent
                                        _this.acceptUpsell(upsell_token, cart_token, result.paymentIntent.id, product_id, upsellCustomFields, isGift);
                                    }
                                });
                            } else {
                                _this.showErrorModal(response.template);
                            }
                            _this.processingComplete();
                        }
                    } else {
                        _this.showErrorModal(response.template);
                        _this.processingComplete();
                    }

                }
            });
        },

        declineUpsell : function(upsell_token, cart_token) {
            this.hideFunnelItem();
            var _this = this;
            Order.declineUpsell(upsell_token, cart_token, function(response){
                if (response.success) {
                    _this.nextState(response.data);
                    _this.processingComplete();
                } else {
                    if (response.data.url_redirect !== null && response.data.url_redirect !== 'null' && response.data.url_redirect !== '') {
                        if (response.data.next_step_type == 'expired_cart') {
                            _this.processingComplete();
                            _this.showErrorModal("This session has expired please wait to be redirected or click <a href='"+response.data.url_redirect+"'>here.</a>", function(){
                                $window.location = response.data.url_redirect;
                            });
                            $window.setTimeout(function(){
                                _this.trigger("hide_error_modal");
                            }, 3000);
                        } else {
                            $window.location = response.data.url_redirect;
                        }
                    } else {
                        _this.showErrorModal(response.template);
                        _this.processingComplete();
                    }

                }
            });
        },

        funnelStart : function() {
            this.fadeInModalScreen();
            this.firstProcessing();
        },

        displayError: function (errors) {
            this.processingComplete();
            if (errors.messages && Array.isArray(errors.messages) && errors.messages.length > 0) {
                this.showErrorModal('<h2>Please fix the following:</h2><div ng-repeat="(field, messages) in data"><li ng-repeat="message in messages">[[message]]</li></div>', errors);
            } else {
                this.showErrorModal('<h2>There was an issue processing your card</h2>');
            }
        },

        firstProcessing : function() {
            this.trigger('show_first_processing');
        },

        processing : function() {
            this.showModalScreen();
            this.trigger("processing");
        },

        processingComplete : function() {
            this.trigger("processing_complete");
        },

        showErrorModal : function(template, data, fn) {
            _this = this;
            _this.trigger("show_error_modal", template, data, fn);
            $window.setTimeout(function(){
                _this.hideErrorModal();
            }, 5000);
        },

        hideErrorModal : function() {
            this.trigger("hide_error_modal");
            this.trigger("hide_modal_screen");
        },

        fadeInModalScreen : function() {
            this.trigger("fade_in_modal_screen");
        },

        showModalScreen : function() {
            this.trigger("show_modal_screen");
        },

        hideFunnelItem : function() {
            this.trigger("hide_funnel_item");
        },

        handleRedirect : function(url) {
            //Handle redirecting parent if checkout is taking place inside an xcomponent iFrame
            if (window.xprops && window.xprops.notifyRedirect) {
                window.xprops.notifyRedirect(url);
            } else {
                $window.location = url;
            }
        },

        listen : function(fn, state) {

            this.listeners.push({
                "state" : state,
                "fn" : fn
            });
        },

        trigger : function(state, template, callback) {

            if (typeof template === 'undefined') {
                for(var i = 0; i < this.listeners.length; i++) {
                    if (this.listeners[i].state === state) {
                        this.listeners[i].fn();
                    }
                }
            } else {
                for(var i = 0; i < this.listeners.length; i++) {
                    if (this.listeners[i].state === state) {
                        this.listeners[i].fn(template, callback);
                    }
                }
            }


        },

        tokenizeCard : function(orderData, checkoutContext, stripeElement, braintreeHostedFieldsInstance, callback) {
            orderData = JSON.parse(JSON.stringify(orderData));
            // call the correct tokenization handler
            if ($window.samcart.stripe) {
                this.tokenizeCardWithStripe(orderData, checkoutContext, stripeElement, callback);
            } else if ($window.samcart.authorizeNet) {
                this.tokenizeCardWithAuthorizeNet(orderData, checkoutContext, callback);
            } else if ($window.samcart.braintree) {
                this.tokenizeCardWithBraintree(orderData, checkoutContext, braintreeHostedFieldsInstance, callback);
            } else if ($window.samcart.easypaydirect) {
                this.tokenizeCardWithEasyPayDirect(orderData, checkoutContext, callback);
            } else if ($window.samcart.internalSandbox) {
                this.tokenizeCardWithInternalSandbox(orderData, checkoutContext, callback);
            } else {
                $window.samcart.spreedly = true;
                this.tokenizeCardWithSpreedly(orderData, checkoutContext, callback);
            }
        },

        tokenizeCardWithStripe : function(orderData, checkoutContext, stripeElement, callback) {
            // Stripe purchases now use Stripe's PaymentMethod API for tokenizing the card.
            // This is part of our updates to support European SCA (strong customer authentication).
            // SCA support for other processors TBD. Only implementing SCA mods for Stripe at this time.

            // Create payment method and send the resulting payment method ID to the next state
            StripeElements
                .createPaymentMethod('card', stripeElement)
                .then(function (result) {
                    if (result.error) {
                        this.showErrorModal('<h2>' + result.error.message + '</h2>');
                        this.processingComplete();
                        callback(orderData, checkoutContext, false);
                    } else {
                        orderData.stripe_token = result.paymentMethod.id;
                        checkoutContext.payment.stripe_token = result.paymentMethod.id;
                        callback(orderData, checkoutContext, true);
                    }
                }.bind(this))
                .catch(function (error) {
                    this.showErrorModal('<h2>' + error.message + '</h2>');
                    this.processingComplete();
                    callback(orderData, checkoutContext, false);
                }.bind(this));
        },

        tokenizePaymentMethodWithStripe : function(orderData, checkoutContext, elements, callback) {
            // Stripe purchases now use Stripe's PaymentMethod API for tokenizing the Payment Method.

            // Create payment method and send the resulting payment method ID to the next state
            StripeElements
                .createPaymentMethod({elements: elements})
                .then(function (result) {
                    if (result.error) {
                        var msg = result.error.message;
                        if (result.error.code === 'parameter_invalid_empty'
                          && result.error.param === "billing_details[address][country]"
                        ) {
                          msg = "Klarna is not currently supported for products with a Free Trial or Pay What You Want "
                            + "payment option. Please use another payment method and try again.";
                        }
                        this.showErrorModal('<h2>' + msg+ '</h2>');
                        this.processingComplete();
                        callback(orderData, checkoutContext, false);
                    } else {
                        orderData.stripe_token = result.paymentMethod.id;
                        checkoutContext.payment.stripe_token = result.paymentMethod.id;
                        callback(orderData, checkoutContext, true);
                    }
                }.bind(this))
                .catch(function (error) {
                    this.showErrorModal('<h2>' + error.message + '</h2>');
                    this.processingComplete();
                    callback(orderData, checkoutContext, false);
                }.bind(this));
        },

        tokenizeCardWithAuthorizeNet: function(orderData, checkoutContext, callback) {
            var _this = this;
            var authData = {};

            authData.clientKey = window.samcart.authorizeNetKey;
            authData.apiLoginID = window.samcart.authorizeNetApiLoginId;

            var cardData = {};
            cardData.cardNumber = document.getElementById("ccnum").value;
            cardData.month = document.getElementById("expmonth").value;
            cardData.year = document.getElementById("expyear").value;
            cardData.cardCode = document.getElementById("CVV2").value;

            var secureData = {};
            secureData.authData = authData;
            secureData.cardData = cardData;
            Accept.dispatchData(secureData, function(response) {
                if (response.messages.resultCode === "Error") {
                    var messages = '<h2>There was an issue processing your card</h2>';

                    if (response.messages.message.length >= 1) {
                        // Show validation messages from Accept.js
                        var i = 0;
                        messages = '<h2>Please fix the following:</h2>';
                        while (i < response.messages.message.length) {
                            messages += '<li>' + response.messages.message[i].text;
                            i = i + 1;
                        }
                    }
                    _this.processingComplete();
                    _this.showErrorModal(messages);
                    callback(orderData, checkoutContext, false);
                } else {
                    // Clear card data before sending over the wire to backend
                    delete orderData.card;

                    // Instead send the opaque tokenized data
                    orderData.authnet_token = response.opaqueData;
                    checkoutContext.payment.authnet_token = response.opaqueData;
                    callback(orderData, checkoutContext, true);
                }
            });
        },

        tokenizeCardWithBraintree: function(orderData, checkoutContext, braintreeHostedFieldsInstance, callback) {
            var _this = this;

            var formIsInvalid = false;
            var state = braintreeHostedFieldsInstance.getState();

            // Validate fields
            Object.keys(state.fields).forEach(function(field) {
                if (!state.fields[field].isValid) {
                    $(state.fields[field].container).addClass('is-invalid');
                    formIsInvalid = true;
                    document.getElementById(state.fields[field].container.id + '-error').style.display = 'block';
                } else {
                    document.getElementById(state.fields[field].container.id + '-error').style.display = 'none';
                }
            });

            if (formIsInvalid) {
                // Errors shown inline
                _this.hideErrorModal();
                _this.processingComplete();
                callback(orderData, checkoutContext, false);
            } else {
                braintreeHostedFieldsInstance.tokenize(function(tokenizeErr, payload) {
                    if (tokenizeErr) {
                        _this.hideErrorModal();
                        _this.processingComplete();
                        callback(orderData, checkoutContext, false);
                    } else {
                        orderData.braintree_token = payload.nonce;
                        checkoutContext.payment.braintree_token = payload.nonce;
                        callback(orderData, checkoutContext, true);
                    }
                });
            }
        },

        tokenizeCardWithEasyPayDirect: function(orderData, checkoutContext, callback) {
            var _this = this;

            // Inline validation
            CollectJS.config.validationCallback = function(field, status, message) {
                var errorElement = document.getElementById(field + '-error');
                if (!status) {
                    // Errors shown inline
                    errorElement.hidden = false;
                    _this.hideErrorModal();
                    _this.processingComplete();
                } else if (status && errorElement.hidden != true) {
                    errorElement.hidden = true;
                }
            };

            // Determine if errors are shown
            var ccnumberError = !(document.getElementById('ccnumber-error').hidden);
            var ccexpError = !(document.getElementById('ccexp-error').hidden);
            var cvvError = !(document.getElementById('cvv-error').hidden);
            var hasError = (ccnumberError || ccexpError || cvvError);

            // Set timeout callback error message
            CollectJS.config.timeoutCallback = function(hasError) {
                if (hasError) {
                    var message = '<h2>Please fill in all payment fields and try again</h2>';
                } else {
                    var message = '<h2>Submission failed, please try again</h2>';
                }
                _this.showErrorModal(message);
                _this.processingComplete();
                callback(orderData, checkoutContext, false);
            };

            // Set callback handler
            CollectJS.config.callback = function(response) {
                // easypaydirectToken exists, ready to proceed
                var paymentToken = response.token;
                orderData.easypaydirect_token = paymentToken;
                checkoutContext.payment.easypaydirect_token = paymentToken;
                callback(orderData, checkoutContext, true);
            };

            if (hasError) {
                // Has errors, display error modal
                var message = '<h2>Please fill in all payment fields and try again</h2>';
                _this.showErrorModal(message);
                _this.processingComplete();
                callback(orderData, checkoutContext, false);
            } else {
                // Submit tokenization to EPD
                CollectJS.startPaymentRequest();
            }
        },

        tokenizeCardWithSpreedly: function(orderData, checkoutContext, callback) {
            // override card number for sandbox mode
            if (orderData.sandbox) {
                orderData.card.number = '4111111111111111';
            }

            // no tokenization handler called, just invoke the callback
            callback(orderData, checkoutContext, false);
        },

        tokenizeCardWithInternalSandbox: function(orderData, checkoutContext, callback) {
            // follows stripe test card numbers
            var ccTokens = [
                { number: '4242424242424242', token: 'internal_samcart_visa'},
                { number: '4000056655665556', token: 'internal_samcart_visa_debit' },
                { number: '5555555555554444', token: 'internal_samcart_mastercard' },
                { number: '5200828282828210', token: 'internal_samcart_mastercard_debit' },
                { number: '5105105105105100', token: 'internal_samcart_mastercard_prepaid' },
                { number: '378282246310005',  token: 'internal_samcart_amex' },
                { number: '6011111111111117', token: 'internal_samcart_discover' },
                { number: '3056930009020004', token: 'internal_samcart_diners' },
                { number: '3566002020360505', token: 'internal_samcart_jcb' }
            ];

            var ccTokenDecline = { token: 'internal_samcart_decline' };

            var errors = [];

            // cleanup form data
            var ccNumber = (orderData.card.number || '').replace(/\s/g, '');
            var ccExpMonth = Number((orderData.card.exp_month || '').replace(/\s/g, ''));
            var ccExpYear = (orderData.card.exp_year || '').replace(/\s/g, '');
            var ccCvc = (orderData.card.cvc || '').replace(/\s/g, '');

            // lookup card token, default to decline token
            var ccToken = ccTokens.filter(function(obj) {
                return obj.number === ccNumber
            })[0] || ccTokenDecline;

            // parse expiration year
            var ccExpYearResolved = Number(ccExpYear);
            if (Number.isNaN(ccExpYearResolved) || !Number.isInteger(ccExpYearResolved) || ccExpYearResolved < 0) {
                errors.push('Expiration year is invalid.');
            } else {
                // verify year
                if (ccExpYearResolved < 100) {
                    // this is a 2 digit year (00 - 99) -- ONE PAGE FUNNEL
                    // we have to extrapolate the millennium and century
                    var currentYear = new Date().getFullYear();

                    // this looks odd, but we really want to keep only the millennium and
                    // century, then add the 2 digit year.
                    var assumedYear = Number(currentYear.toString().substr(0, 2) + '00') + ccExpYearResolved;

                    if ((assumedYear - currentYear) <= -2) {
                        // this is more than 2 years off, maybe we are at the edge of a century
                        currentYear += 100;
                        assumedYear = Number(currentYear.toString().substr(0, 2) + '00') + ccExpYearResolved;
                    }

                    ccExpYearResolved = assumedYear;
                } else if (ccExpYearResolved < 1000 || ccExpYearResolved > 9999) {
                    // this is not a 4 digit year
                    errors.push('Expiration year is invalid.');
                }
            }

            // verify expiration month
            if (ccExpMonth < 1 || ccExpMonth > 12) {
                errors.push('Expiration month is invalid.');
            }

            // verify cvc length - all test cards are 3 or 4 digit codes
            if (ccCvc.length < 3 || ccCvc.length > 4) {
                errors.push('CVC is invalid.');
            }

            // report errors
            if (errors.length > 0) {
                var messages = '<h2>There was an issue processing your card</h2>';

                if (errors.length >= 1) {
                    messages = '<h2>Please fix the following:</h2>';
                    for (var ii = 0; ii < errors.length; ii++) {
                        messages += '<li>' + errors[ii] + '</li>';
                    }
                }
                _this.processingComplete();
                _this.showErrorModal(messages);
                callback(orderData, checkoutContext, false);
            } else {
                // clear card data before sending over the wire to backend
                delete orderData.card;

                // send the tokenized data
                orderData.internal_token = ccToken.token + '_' + ccExpYearResolved.toString() + '_' + ('0' + ccExpMonth.toString()).substr(-2);
                callback(orderData, checkoutContext, true);
            }
        }

    };

    return Checkout;
}]);
